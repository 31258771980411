::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body {
  background-color: #000d20e3;
  color: white !important;
  overflow: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.quote_btn{
  background-color: #347928;
}

.quote_btn:hover{
  border: 1px solid white;
  color: white;

}
.image-container {
  max-width: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.insta:hover {
  background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff0000 !important;
  background-color: aqua !important;

}

.whatsapp i:hover {
  background: #25D366;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.linkdlin i:hover {
  background: #0077B5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.youtube i:hover {
  background: #FF0000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.dropdown-item:active {
  background-color: #5DE1E6;
}

.read :hover {
  color: black !important;
}

.arrow:hover {
  border: 1px solid black !important;
  background-color: white;
  color: black;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.navvvv a {
  text-decoration: none;
}

.zoomIn1 {
  transition: all 1s ease;
}

.zoomIn1:hover {
  transform: scale(1.1);
}

.colorb1:hover {
  background-color: white !important;
  border: 1px solid black;
}

:root {
  --primary: #347928;
  --secondary: #34AD54;
  --light: #EEF9FF;
  --dark: #091E3E;
}

h1,
h2,
.fw-bold {
  font-weight: 800 !important;
}

h3,
h4,
.fw-semi-bold {
  font-weight: 700 !important;
}

h5,
h6,
.fw-medium {
  font-weight: 600 !important;
}

.fontf {
  font-family: 'Nunito', sans-serif;
}

.item0:hover {
  background-color: #7FDBDA !important;
}

.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  transition: .5s;
}

.btn-primary,
.btn-secondary {
  color: #FFFFFF;
  box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
  box-shadow: inset 0 0 0 0 var(--primary);
}

.btn-secondary:hover {
  box-shadow: inset 0 0 0 0 var(--secondary);
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 30px;
  height: 30px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  font-family: 'Nunito', sans-serif;
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 600;
  outline: none;
  transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:active {
  color: #2d2d2d;
}

.navbar-dark .navbar-brand h1 {
  color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
    background: #000;
    color: #000 !important;
  }

  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    color: white;
    /* background-color: #000; */
  }

  .navbar-dark .navbar-brand h1 {
    color: var(--primary);
  }
}

.colorb {
  background-color: white;
}

.color {
  color: #0077B5;
  font-size: 18px;
}

.color1 {
  color: #0077B5;
  border-bottom: 1px solid white;
}

.contactDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.textHead {
  font-weight: bold;
  font-size: 20px;
}

.textHead1 {
  font-weight: bold;
  font-size: 18px;
}

.textHead2 {
  font-weight: bold;
  color: #0077B5;
}
.table-bg-clr{
  background-color: #ADD8E6 !important;
}

@media (min-width: 992px) {
  .navbar-dark {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, .1);
    z-index: 999;
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: #FFFFFF;
  }

  .navbar-dark .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    background:#2d2d2d ;
    transition: .5s;
  }

  .navbar-dark .navbar-nav .nav-link:hover::before,
  .navbar-dark .navbar-nav .nav-link:active::before {
    width: 100%;
    left: 0;
  }

  .navbar-dark .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }

  .sticky-top.navbar-dark .navbar-brand h1 {
    /* color: var(--primary); */
    color: white;
  }
}

@media screen and (max-width: 480px) {
  .staricon {
    font-size: 80px !important;
  }
  .khalidd{
    font-size: 8px !important;
  }
}
.khalid{
  text-align: center;
}
.khaliddd{
  font-size: 26px;
  margin-top: 5%;
}

/*** Carousel ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(99, 224, 255, 0.1);
  z-index: 1;
}

@media (max-width: 576px) {
  .carousel-caption h5 {
    font-size: 14px;
    font-weight: 500 !important;
  }

  .carousel-caption h1 {
    font-size: 20px;
    font-weight: 600 !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;

}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate(-120deg)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.rollIn {
  animation-name: rollIn
}

@keyframes rollOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate(120deg)
  }
}

.rollOut {
  animation-name: rollOut
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

.zoomIn {
  animation-name: zoomIn
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes slideOutDown {
  0% {
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0)
  }
}

.slideOutDown {
  animation-name: slideOutDown
}


.logo_img {
  width: 120px;
  height: 70px;
}

.bg-header {
  background: linear-gradient(rgba(121, 214, 214, 0.4), rgba(121, 214, 214, 0.4)), url("https://auqafboardwb.org/wp-content/uploads/2017/02/gal16.jpg") center center no-repeat;
  background-size: cover;
}
.clickable-text{
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}